<template>
  <div>
    <img v-if="imgList.img" class="tui-img" :src="imgList.img" alt="">
    <div class="bigBox">
      <div v-if="currentItem" class="liveTips">
        <div class="roundTips" />
        <span>今日直播课程</span>
      </div>
      <div v-if="currentItem" class="videoBox">
        <div class="leftVidioBox">
          <div class="video" @mouseover="mouseover" @mouseout="mouseout">
            <img v-if="currentItem && currentItem.state != 2" class="pcPictureUrl" :src="currentItem.pcPictureUrl">
            <img v-if="currentItem &&
              currentItem.state == 2 &&
              currentItem.aliPlaybackUrl == '2'
              " class="pcPictureUrl" :src="currentItem.pcPictureUrl">
            <video v-if="currentItem &&
              currentItem.state == 2 &&
              currentItem.aliPlaybackUrl == '1'
              " class="videoContent" :autoplay="true" :controls="true" :src="currentItem.playbackUrl" />
            <!-- https://staticfile.xlcwx.com/userfiles/videos/202201/2222bb23b6034c4c839625e822312acc20220122173532554.mp4 -->
            <div id="cover" class="cover">
              <div class="coverBtn" @click="goDetail(currentItem)">
                点击进入
              </div>
            </div>
          </div>
          <div class="courseBox">
            <div v-if="currentItem && currentItem.state == 2" class="tag tagBack">
              已结束
            </div>
            <div v-if="currentItem && currentItem.state == 1" class="tag tagLive">
              直播中
            </div>
            <div v-if="currentItem && currentItem.state == 0" class="tag tagNot">
              未开始
            </div>
            <div class="courseTitle">
              {{ currentItem ? currentItem.name : "" }}
            </div>
            <div class="teacherBox">
              <img v-if="currentItem && currentItem.lecturerUrl" class="teacherImg"
                :src="currentItem ? currentItem.lecturerUrl : ''">
              <span v-if="currentItem && currentItem.lecturerName" class="teacherName">讲师：{{ currentItem ?
                currentItem.lecturerName : "" }}</span>
            </div>
          </div>
        </div>
        <div class="rightList">
          <div v-for="item in liveTodayList" :key="item.id" class="courseItem" @click="chooseCourse(item)">
            <div class="titleBox">
              <div v-if="item.state == 2" class="tag tagBack">已结束</div>
              <div v-if="item.state == 1" class="tag tagLive">直播中</div>
              <div v-if="item.state == 0" class="tag tagNot">未开始</div>
              <div class="title">{{ item.name }}</div>
            </div>
            <div class="tipsBox">
              <div class="leftTips">{{ item.kindName }}</div>
              <div class="rightTime">开播时间：{{ item.zbBeginTime }}</div>
            </div>
            <div v-show="item.id == currentItem.id" class="onCourse" />
          </div>
        </div>
      </div>
      <!-- 搜索 -->
      <div class="searchBox">
        <kind-choose-view :show-school="true" :show-kc="false" :show-zy="false" :show-level="false" :type="null"
          :if-img="false" @isOk="isOk" />
      </div>
      <!-- 列表 -->
      <div class="courseList">
        <div v-for="item in list" :key="item.id" class="courstItem" @click="goDetail(item)">
          <img class="itemImg" :src="item.pcPictureUrl">
          <div class="contentItem">
            <div class="titleItem">{{ item.name }}</div>
            <div class="timeItem">
              <span>直播时间：{{ item.zbBeginTime }}</span>
              <!-- <span class="endText">已结束</span> -->
              <!-- <span class="loading">报名中</span> -->
            </div>
            <div class="line" />
            <div class="teacherItem">
              <div class="itemBox">
                <img v-if="item.lecturerUrl" class="teacherImgItem" :src="item.lecturerUrl">
                <div v-if="item.lecturerName" class="ter_name">
                  讲师：{{ item.lecturerName }}
                </div>
              </div>
              <div>查看详情></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination class="pagination" :current-page="page.pageNum" :page-sizes="[12, 24, 36, 48]"
        :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import { getSurfaceList } from '@/api/home'
import KindChooseView from '../../components/kindChooseViews.vue'
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo, getDomain } from '@/api/cookies'
import { Know } from '@/api/know.js'
const know = new Know()
export default {
  components: {
    KindChooseView
  },
  data() {
    return {
      page: {
        pageSize: 12,
        pageNum: 1,
        total: 0
      },
      list: [], // 课程列表
      currentItem: null, // 选中的公开课
      searchForm: {},
      userInfo: null,
      liveTodayList: [], // 今日直播列表
      zbStartTime: null,
      imgList: {},
      domain: null
    }
  },
  async created() {
    this.domain = getDomain()
    const date = new Date()
    this.zbStartTime = date.toLocaleDateString()
    this.liveToday()
    this.getList()
    this.userInfo = await getInfo()
    this.getImg()
  },
  methods: {
    mouseover() {
      const cover = document.getElementById('cover')
      cover.style.display = 'flex'
    },
    mouseout() {
      const cover = document.getElementById('cover')
      cover.style.display = 'none'
    },
    isOk(val) {
      this.searchForm.zyId = val.zyId
      this.searchForm.kindId = val.kind
      this.searchForm.schoolId = val.school
      this.getList()
    },
    /* 公开课列表 */
    getList() {
      const data = {
        ...this.page,
        ...this.searchForm
      }
      delete data.total
      know.getPublicityClassList(data).then((res) => {
        this.list = res.rows
        this.page.total = res.total
      })
    },
    /* 今日直播列表 */
    liveToday() {
      const data = {
        pageSize: 999,
        pageNum: 1,
        zbStartTime: this.zbStartTime,
        zbEndTime: this.zbStartTime
      }
      know.getPublicityClassList(data).then((res) => {
        if (res.rows.length > 0) {
          this.currentItem = res.rows[0]
        }
        this.liveTodayList = res.rows
        this.liveTodayList.map((item, index) => {
          if (item.state == 1) {
            this.liveTodayList.unshift(this.liveTodayList.splice(index, 1)[0])
          }
        })
      })
    },
    goDetail(item) {
      if (this.userInfo) {
        if (item.state == 2 && !item.playbackUrl) {
          this.$message({
            type: 'error',
            message: '回放生成中'
          })
        } else {
          this.addRecord(item)
          const routeUrl = this.$router.resolve({
            path: '/publicClass/publicClassDetail',
            query: {
              id: item.id,
              imQunId: item.imQunId
            }
          })
          window.open(routeUrl.href, '_blank')
        }
      } else {
        Vue.prototype.goLoginView(true)
      }
      /* if (item.state == 2 && !item.playbackUrl) {
                    this.$message({
                        type: 'error',
                        message: '回放生成中',
                    });
                } else {
                    this.addRecord(item)
                    let routeUrl = this.$router.resolve({
                        path: "/publicClass/publicClassDetail",
                        query: {
                            id: item.id,
                        },
                    })
                    window.open(routeUrl.href, '_blank')
                } */
    },
    /* 点击公开课添加观看记录 */
    addRecord(item) {
      const data = {
        userId: this.userInfo ? this.userInfo.id : null,
        userName: this.userInfo ? this.userInfo.userName : null,
        classId: item.id,
        port: this.userInfo ? this.userInfo.userSource : null
      }
      know.addPublicityClassStudentRecord(data).then((res) => { })
    },
    /* 今日直播课程选择 */
    chooseCourse(item) {
      this.currentItem = JSON.parse(JSON.stringify(item))
    },
    getImg() {
      getSurfaceList(4, undefined, this.domain.entrantsType).then((res) => {
        if (res.code == 0) {
          if (res.msg.length > 0) {
            this.imgList = res.msg[0]
          } else {
            this.imgList = {}
          }
        }
      })
    },
    /* 搜索 */
    selectPageList() {
      this.page = {
        pageSize: 12,
        pageNum: 1,
        total: 0
      }
      this.getList()
    },
    /* 每页条数 */
    handleSizeChange(val) {
      this.page.pageSize = val
      this.page.pageNum = 1
      this.getList()
    },
    /* 当前页码 */
    handleCurrentChange(val) {
      this.page.pageNum = val
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.bigBox {
  width: 1220px;
  height: auto;
  margin: auto;

  .liveTips {
    width: 202px;
    margin: auto;
    font-size: #333333;
    font-size: 32px;
    font-weight: bold;
    position: relative;
    margin-top: 50px;
    margin-bottom: 32px;

    .roundTips {
      position: absolute;
      top: -10px;
      left: -10px;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background: linear-gradient(180deg,
          rgba(255, 175, 81, 0.8),
          rgba(255, 175, 81, 0.2));
    }
  }

  .videoBox {
    width: 1220px;
    height: 545px;
    border-radius: 8px;
    background-color: #222222;
    padding: 40px;
    display: flex;
    margin-bottom: 40px;

    .leftVidioBox {
      width: 720px;
      height: 465px;
      border-radius: 8px;
      margin-right: 20px;

      .video {
        width: 720px;
        height: 405px;
        border-radius: 8px 8px 0 0;
        position: relative;

        .videoContent {
          width: 100%;
          height: 100%;
        }

        .pcPictureUrl {
          width: 100%;
          height: 100%;
        }

        .cover {
          /* width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        justify-content: center;
                        align-items: center; */
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -30px;
          margin-left: -100px;
          display: none;

          .coverBtn {
            width: 200px;
            height: 60px;
            background-color: rgba(255, 94, 84, 0.8);
            border-radius: 8px;
            color: #ffffff;
            font-size: 18px;
            line-height: 60px;
            text-align: center;
            cursor: pointer;
          }
        }
      }

      .courseBox {
        width: 720px;
        height: 60px;
        border-radius: 0 0 8px 8px;
        background-color: #383838;
        padding: 20px;
        display: flex;
        align-items: center;

        .courseTitle {
          width: 410px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 62px;
          color: #ffffff;
        }

        .teacherBox {
          display: flex;
          align-items: center;

          .teacherImg {
            width: 32px;
            height: 32px;
            border: 1px #ffffff solid;
            border-radius: 16px;
            margin-right: 12px;
          }

          .teacherName {
            color: #ffffff;
            font-size: 14px;
            width: 104px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .rightList {
      width: 400px;
      height: 465px;
      border-radius: 8px;
      background-color: #383838;
      padding: 20px 20px 0 20px;
      overflow: auto;

      .courseItem {
        cursor: pointer;
        width: 360px;
        height: 88px;
        border-radius: 8px;
        background-color: #2c2c2c;
        padding: 20px;
        margin-bottom: 12px;
        position: relative;

        .titleBox {
          margin-bottom: 16px;
          display: flex;

          .title {
            width: 257px;
            color: #ffffff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .tipsBox {
          color: rgba(255, 255, 255, 0.5);
          font-size: 12px;
          display: flex;
          justify-content: space-between;
        }

        .onCourse {
          width: 360px;
          height: 88px;
          border-radius: 8px;
          border: solid 2px #ff5e51;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .searchBox {
    margin-bottom: 40px;
  }

  ::v-deep .zychoice {
    padding-left: 50px;
  }

  .courseList {
    display: flex;
    flex-wrap: wrap;

    .courstItem {
      cursor: pointer;
      width: 287px;
      height: 358px;
      border-radius: 8px;
      margin-right: 18px;
      margin-bottom: 24px;

      .itemImg {
        height: 161.44px;
        width: 287px;
        border-radius: 8px 8px 0 0;
      }

      .contentItem {
        height: 167px;
        border-radius: 0 0 8px 8px;
        background-color: #ffffff;
        padding: 12px 16px;

        .titleItem {
          color: #373a42;
          height: 48px;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 16px;
        }

        .timeItem {
          font-size: 14px;
          color: #999999;
          display: flex;
          justify-content: space-between;

          .loading {
            color: #ff5e51;
          }
        }

        .line {
          width: 255px;
          height: 1px;
          background-color: #eeeeee;
          margin-top: 20px;
          margin-bottom: 16px;
        }

        .teacherItem {
          color: #666666;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .itemBox {
            display: flex;
            align-items: center;

            .teacherImgItem {
              width: 24px;
              height: 24px;
              border-radius: 12px;
              margin-right: 8px;
            }

            .ter_name {
              width: 150px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .tag {
    height: 20px;
    border-radius: 3px;
    padding: 0 8px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-right: 8px;
  }

  .tagBack {
    background: linear-gradient(136deg, #727a8a 0%, #494e59 100%);
  }

  .tagLive {
    background: linear-gradient(138deg, #ff9a51 0%, #ff5e51 100%);
  }

  .tagNot {
    background: linear-gradient(136deg, #727a8a 0%, #555555 100%);
  }
}

.tui-img {
  margin: 0;
  width: 100%;
}
</style>
